/* Can't style the html tag with theme-ui, so put styles in here */
@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/RobotoMono-Regular.ttf") format("truetype");
  /* add additional src declarations for each additional font style (e.g. bold, italic) */
  font-weight: 400;
  font-style: regular;
}

@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/RobotoMono-Medium.ttf") format("truetype");
  /* add additional src declarations for each additional font style (e.g. bold, italic) */
  font-weight: 500;
  font-style: medium;
}

@font-face {
  font-family: "Roboto Mono Regular";
  src: url("./assets/fonts/RobotoMono-Regular.ttf") format("truetype");
  /* add additional src declarations for each additional font style (e.g. bold, italic) */
  font-weight: 400;
  font-style: regular;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("./assets/fonts/RobotoMono-Medium.ttf") format("truetype");
  /* add additional src declarations for each additional font style (e.g. bold, italic) */
  font-weight: 500;
  font-style: medium;
}


@font-face {
  font-family: "Montserrat Subrayada";
  src: url("./assets/fonts/VT323-Regular.ttf") format("truetype");
  /* add additional src declarations for each additional font style (e.g. bold, italic) */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Teko";
  src: url("./assets/fonts/Teko-Regular.ttf") format("truetype");
  /* add additional src declarations for each additional font style (e.g. bold, italic) */
}

@font-face {
  font-family: "VT323";
  src: url("./assets/fonts/VT323-Regular.ttf") format("truetype");
  /* add additional src declarations for each additional font style (e.g. bold, italic) */
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

.banner-container {
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
  background-attachment: local;
  background-image: url(./assets/images/banner-backgrounds/listing.svg);
}

.banner-container--listing {
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
  background-attachment: local;
  background-image: url(./assets/images/banner-backgrounds/listing.svg);
}

.banner-container--zenlink-pool {
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
  background-attachment: local;
  background-image: url(./assets/images/banner-backgrounds/zenlink-pool.svg);
}

@media only screen and (max-width: 820px) {
  .banner-container--listing {
    background-image: url(./assets/images/banner-backgrounds/listing-mobile.svg);
  }

  .banner-container--zenlink-pool {
    background-image: url(./assets/images/banner-backgrounds/zenlink-pool-mobile.png);
  }
}

span.donut-tooltip__currency {
  display: inline-block;
  margin-bottom: 0.3em;
}

span.donut-tooltip__currency-logo--ceUSDT:before {
  background-image: url(./assets/images/currencies/ce-usdt.svg);
  background-size: 23px;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 23px;
  height: 23px;
  content: "";
}

span.donut-tooltip__currency-logo--DOT:before {
  background-image: url(./assets/images/currencies/dot.svg);
  background-size: 23px;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 23px;
  height: 23px;
  content: "";
}

span.donut-tooltip__currency-logo--DAI:before {
  background-image: url(./assets/images/currencies/dai.svg);
  background-size: 23px;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 23px;
  height: 23px;
  content: "";
}

span.donut-tooltip__currency-logo--BUSD:before {
  background-image: url(./assets/images/currencies/busd.svg);
  background-size: 23px;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 23px;
  height: 23px;
  content: "";
}

span.donut-tooltip__currency-logo--ceUSDC:before {
  background-image: url(./assets/images/currencies/ce-usdc.svg);
  background-size: 23px;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 23px;
  height: 23px;
  content: "";
}

span.donut-tooltip__currency-logo--WASTR:before {
  background-image: url(./assets/images/currencies/astr.svg);
  background-size: 23px;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 23px;
  height: 23px;
  content: "";
}

span.donut-tooltip__currency-logo--WBTC:before {
  background-image: url(./assets/images/currencies/wbtc.svg);
  background-size: 23px;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 23px;
  height: 23px;
  content: "";
}

span.donut-tooltip__currency-logo--WETH:before {
  background-image: url(./assets/images/currencies/weth.svg);
  background-size: 23px;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 23px;
  height: 23px;
  content: "";
}

/* .mobile-table tr th:first-child {
  text-align: left !important;
}

.mobile-table .table-sub-content {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  height: 40px;
  align-items: center;
}

.mobile-table .sub-content-body {
  width: 40px;
  text-align: center;
} */

.react-reel__group {
  transition-delay: 0ms;
  transition-timing-function: ease-in-out;
  transform: translate(0, 0);
}

.react-reel__group .react-reel__number {
  height: 1em;
}

.react-reel__reel {
  display: flex;
  align-items: flex-end;
  height: 1.1em;
  line-height: 0.9;
  overflow-y: hidden;
}

#arrows-up-down:hover path {
  fill: #f9c53a;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

#tooltip[data-popper-placement^="top"] > #arrow {
  bottom: -4px;
}

#tooltip[data-popper-placement^="bottom"] > #arrow {
  top: -4px;
}

#tooltip[data-popper-placement^="left"] > #arrow {
  right: -4px;
}

#tooltip[data-popper-placement^="right"] > #arrow {
  left: -4px;
}

.accordion__button {
  background-color: "transparent";
  color: "white";
  cursor: pointer;
  padding: 24px 0;
  width: 100%;
  text-align: left;
  border: none;
}

.accordion__button:hover {
  color: "#FFFFF66";
}

.accordion__button:before {
  float: right;
  line-height: 2;
  margin-top: 10px;
  margin-right: 5px;
  content: "";
  height: 10px;
  width: 10px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(45deg);
}

.accordion__button[aria-expanded="true"]::before,
.accordion__button[aria-selected="true"]::before {
  transform: rotate(-135deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 0;
  animation: fadein 0.35s ease-in;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* ::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
} */

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

/* #claiming-table {
  margin: 20px 0;
  padding: 0;
}

#claiming-table table {
  margin: 20px 0;
  padding: 0;
}

#claiming-table tr:hover {
  background: #333;
  border-radius: 10px;
}

#claiming-table th {
  width: 182px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

#claiming-table th:first-child {
  text-align: left;
}

#claiming-table th,
#claiming-table td {
  margin: 0;
  padding: 6px 12px;
}

#claiming-table th:first-child,
#claiming-table td:first-child {
  padding-left: 10px;
}

#claiming-table th:last-child,
#claiming-table td:last-child {
  padding-right: 10px;
}

#claiming-table .expandedTR {
  background: #333;
  padding: 20px;
}

#claiming-table .expandedRow {
  background: #333;
} */
