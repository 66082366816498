.header-menu {
  color: var(--text-text-1, #fff);
  font-family: VT323;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 108.333% */
}

.main-title {
  color: #fff;

  font-family: VT323;
  font-size: 72px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sub-title {
  color: #fff;

  font-family: VT323;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.content {
  color: #fff;

  font-family: VT323;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.data-text {
  color: #fff;

  font-family: VT323;
  font-size: 89px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sub-title-2 {
  color: var(--text-text-1, #fff);
  text-align: center;
  font-family: VT323;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 108.333% */
}

.card-title {
  color: var(--function-primary-6, #fe207e);
  text-align: right;
  font-family: VT323;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-content {
  color: var(--text-text-5, #1d2129);
  text-align: right;
  font-family: VT323;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 108.333% */
}

.partners-tile {
  color: var(--text-text-5, #1d2129);
  text-align: center;
  font-family: VT323;
  font-size: 72px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
