.tip-box-loader {
    animation: App-logo-spin infinite 3s linear;
    pointer-events: none;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }