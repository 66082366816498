.tv-title {
  color: #fff;
  text-align: center;
  font-family: VT323;
  font-size: 58.018px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tv-start {
  color: #fff;
  text-align: center;
  font-family: VT323;
  font-size: 58.018px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  animation: blink 1.5s linear infinite;
}

@media (max-width: 425px) {
  @keyframes scan-line {
    0% {
      transform: translateY(-100px);
      opacity: 1;
    }
    100% {
      transform: translateY(-200px);
      opacity: 0;
    }
  }
}

@media (min-width: 426px) and (max-width: 1024px) {
  @keyframes scan-line {
    0% {
      transform: translateY(-60px);
      opacity: 1;
    }
    100% {
      transform: translateY(-300px);
      opacity: 0;
    }
  }
}

@media (min-width: 1025px) and (max-width: 1920px) {
  @keyframes scan-line {
    0% {
      transform: translateY(380px);
      opacity: 1;
    }
    100% {
      transform: translateY(0px);
      opacity: 0;
    }
  }
}

@media (min-width: 1921px) {
  @keyframes scan-line {
    0% {
      transform: translateY(380px);
      opacity: 1;
    }
    100% {
      transform: translateY(0px);
      opacity: 0;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes float-small {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}

.floating {
  animation: float 3s ease-in-out infinite;
}

.floating-2 {
  animation: float 2s ease-in-out infinite;
}

.floating-small {
  animation: float-small 2s ease-in-out infinite;
}
